import type { Result } from '@api';
import { Failure, Success, useErrorMessage, useDatahubAPI } from '@api';
import { projectAtom } from '@data/auth';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { environmentAtom } from '@data/fms/environment/states';
import { useAtomCallback } from 'jotai/utils';
import type { BatchLogFile } from '@data/datahub/log/types';

export const usePostLogFilesBatchDownloadAPI = (): {
  loading: boolean;
  postLogFilesBatchDownload: (
    logFileIds: string[],
  ) => Promise<BatchLogFile | undefined>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const { datahubAPI } = useDatahubAPI();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      logFileIds: string[],
    ): Promise<Result<BatchLogFile, AxiosResponse>> => {
      setLoading(true);
      try {
        const res = await datahubAPI.post(
          `/${projectId}/log_files/batch_download`,
          logFileIds.map((logFileId) => ({
            environment_id: environmentId,
            log_file_id: logFileId,
          })),
        );
        return new Success(res.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      } finally {
        setLoading(true);
      }
    },
    [datahubAPI],
  );

  const postLogFilesBatchDownload = useAtomCallback(
    useCallback(
      async (get, _, logFileIds: string[]) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.data.post_log_files_batch_downloads', {
              status: 'failed',
            }),
          });
          return;
        }
        const res = await request(
          project.id,
          environment.environment_id,
          logFileIds,
        );

        if (res.isFailure()) {
          notifyError({
            message: `${t('api.datahub.post_log_files_batch_downloads', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          return;
        }
        return res.value;
      },
      [notifyError, t, getErrorMessage, request],
    ),
  );

  return {
    loading,
    postLogFilesBatchDownload,
  };
};
