import { useCallback, useState } from 'react';
import type { Result } from '@api';
import { Failure, Success, useErrorMessage, useDatahubAPI } from '@api';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useTranslation } from 'react-i18next';
import type {
  BagFile,
  LogFile,
  LogType,
  SyslogFile,
} from '@data/datahub/log/types';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useAtomCallback } from 'jotai/utils';
import { GetObjectCommand, S3 } from '@aws-sdk/client-s3';
import { usePostLogFilesBatchDownloadAPI } from './';

type APIResponse<T> = {
  bags?: BagFile[];
  log_files?: T[];
  page_size: number;
  next_page_token?: string;
};

type FileDownloadErrorType =
  | 'download_error'
  | 'file_not_found'
  | 'network_error';

export const useLogFileAPI = <T>(): {
  loading: boolean;
  files: T[];
  getBagFiles: (vehicleId: string, params: URLSearchParams) => Promise<void>;
  getSyslogFiles: (vehicleId: string, params: URLSearchParams) => Promise<void>;
  getTcpdumpFiles: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
  getCoredumpFiles: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
  downloadFile: (type: LogType, file: LogFile) => Promise<void>;
  downloadSyslogFiles: (syslogFiles: SyslogFile[]) => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<T[]>([]);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const { datahubAPI } = useDatahubAPI({ cancelWhenUnmounting: true });
  const getHasScope = useHasScope();
  const { postLogFilesBatchDownload } = usePostLogFilesBatchDownloadAPI();

  const request = useCallback(
    async (
      type: LogType,
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: URLSearchParams,
    ): Promise<Result<APIResponse<T>, AxiosResponse>> => {
      const path = type === 'bag' ? 'bags' : `log_files/${type}`;
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/${path}?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return axios.isCancel(error)
          ? new Success({
              vehicles: [],
              page_size: 0,
            })
          : new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  const getLogFiles = useAtomCallback(
    useCallback(
      async (
        get,
        _,
        type: LogType,
        vehicleId: string,
        params: URLSearchParams,
      ) => {
        setLoading(true);
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        const capitalizedLogType = `${type.charAt(0).toUpperCase()}${type.slice(
          1,
        )}`;

        setFiles([]);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_log_files', {
              status: 'failed',
              logType: capitalizedLogType,
            }),
          });
          setLoading(false);
          return;
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            type,
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (): Promise<void> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            // リクエストに失敗した場合
            notifyError({
              message: `${t('api.datahub.get_log_files', {
                status: 'failed',
                logType: capitalizedLogType,
              })}: ${getErrorMessage(res)}`,
            });
            return;
          }
          // 取得成功時
          if (!!res && res.isSuccess()) {
            // 配列を結合
            setFiles((prevState) =>
              prevState.concat(
                type === 'bag'
                  ? (res.value.bags as T[])
                  : (res.value.log_files as T[]),
              ),
            );
            if (res.value.next_page_token) {
              // レスポンスに next_page_token がある場合は次のリクエストを行う
              if (params) {
                params.set('page_token', res.value.next_page_token);
              }
              await splitRequest();
            }
          } else {
            setLoading(false);
          }
        };

        await splitRequest();

        setLoading(false);
      },
      [notifyError, request, t, getErrorMessage],
    ),
  );

  const getBagFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope([SCOPES.DescribeBag, SCOPES.DownloadBag])) return;
      await getLogFiles('bag', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const getSyslogFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope([SCOPES.DescribeLogFile, SCOPES.DownloadLogFile]))
        return;
      await getLogFiles('syslog', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const getTcpdumpFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope([SCOPES.DescribeLogFile, SCOPES.DownloadLogFile]))
        return;
      await getLogFiles('tcpdump', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const getCoredumpFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope([SCOPES.DescribeLogFile, SCOPES.DownloadLogFile]))
        return;
      await getLogFiles('coredump', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  /**
   * バッチ情報取得
   */
  const getBatchInfo = useCallback(
    async (logFileId: string) => await postLogFilesBatchDownload([logFileId]),
    [postLogFilesBatchDownload],
  );

  /**
   * S3 からファイルを取得
   * @param {string} logFileId
   * @returns {Promise<Blob>}
   */
  const getBlob = useCallback(
    async (logFileId: string): Promise<Blob> => {
      const batchInfo = await getBatchInfo(logFileId);
      const fileInfo = batchInfo?.found[0];
      const isExistFile = fileInfo?.log_file_id === logFileId;
      if (!batchInfo || !isExistFile) {
        throw new Error('file not found');
      }

      const { access_key_id, secret_access_key, session_token } =
        batchInfo.aws_credential;

      const s3 = new S3({
        region: batchInfo.aws_region,
        credentials: {
          accessKeyId: access_key_id,
          secretAccessKey: secret_access_key,
          sessionToken: session_token,
        },
      });

      const getCommand = new GetObjectCommand({
        Bucket: fileInfo.s3_bucket_name,
        Key: fileInfo.s3_object_key,
      });

      const res = await s3.send(getCommand);
      const bytes = await res.Body?.transformToByteArray();
      if (bytes) {
        return new Blob([bytes]);
      }
      throw new Error('download error');
    },
    [getBatchInfo],
  );

  /**
   * Bag, tcpdump, coredump のダウンロード
   */
  const downloadFile = useCallback(
    async (type: LogType, file: LogFile) => {
      if (file.cloud_type === 'aws') {
        // s3 からダウンロード
        try {
          const blob = await getBlob(file.log_file_id);
          saveAs(blob, file.file_name);
        } catch (err) {
          // ダウンロード失敗
          const errorMessage = (err as Error).message;
          const fileTypeName = type === 'bag' ? 'Bag' : type;
          const getErrorType = (): FileDownloadErrorType => {
            if (errorMessage === 'download error') return 'download_error';
            if (errorMessage === 'network error') return 'network_error';
            return 'file_not_found';
          };
          notifyError({
            message: `${t(
              `driving_history.file_list.download_error.${getErrorType()}`,
              {
                file_type: fileTypeName,
              },
            )}`,
          });
        }
        return;
      }
      // aws 以外の場合直接ファイルにリンク
      window.open(file.download_url, '_blank');
    },
    [getBlob, t, notifyError],
  );

  /**
   * Syslog のダウンロード
   * 複数のファイルをメモリにダウンロードし圧縮しダウンロードする
   */
  const downloadSyslogFiles = useCallback(
    async (syslogFiles: SyslogFile[]) => {
      const zip = new JSZip();
      const responses = await Promise.all(
        syslogFiles.map(async (file) => {
          try {
            if (file.cloud_type === 'aws') {
              // s3 からダウンロード
              const res = await getBlob(file.log_file_id);
              return res;
            }
            // リンクを開く
            window.open(file.download_url, '_blank');
            return;
          } catch (err) {
            return (err as Error).message.replaceAll(' ', '_');
          }
        }),
      );
      const downloadError = responses.find((res) => typeof res === 'string');
      if (downloadError) {
        // ダウンロード失敗
        notifyError({
          message: `${t(
            `driving_history.file_list.download_error.${downloadError}`,
            {
              file_type: 'Syslog',
            },
          )}`,
        });
        return;
      }
      const filteredBlobs = responses.filter((res) => res instanceof Blob);
      // blob を含まない場合は処理を中断
      if (!filteredBlobs.length) return;
      syslogFiles.forEach((file, i) => {
        // 圧縮するファイルに追加
        zip.file(file.file_name, new Blob([filteredBlobs[i]]));
      });
      // ZIP に圧縮
      try {
        const blob = await zip.generateAsync({ type: 'blob' });
        // ダウンロード
        saveAs(blob, 'syslog.zip');
      } catch (err) {
        // ファイル圧縮エラー
        notifyError({
          message: `${t('api.datahub.download_syslog_files', {
            status: 'failed',
          })}: ${err}`,
        });
      }
    },
    [notifyError, t, getBlob],
  );

  return {
    files,
    loading,
    getBagFiles,
    getSyslogFiles,
    getTcpdumpFiles,
    getCoredumpFiles,
    downloadFile,
    downloadSyslogFiles,
  };
};
