import { useLogFileAPI } from '@api/datahub/getLogFile';
import type { SyslogFile } from '@data/datahub/log/types';
import { Badge, Box, Button, CircularProgress } from '@mui/material';
import { useConcatWord } from '@utils/i18n';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  syslogFiles: SyslogFile[];
};

const SyslogDownloadButton: React.FC<Props> = ({ syslogFiles }: Props) => {
  const { t } = useTranslation();
  const { concat } = useConcatWord();
  const { downloadSyslogFiles } = useLogFileAPI();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClickDownload = useCallback(async () => {
    setIsDownloading(true);
    await downloadSyslogFiles(syslogFiles);
    setIsDownloading(false);
  }, [syslogFiles, downloadSyslogFiles]);

  return (
    <Box position="relative">
      <Badge color="primary" badgeContent={syslogFiles.length}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          disabled={syslogFiles.length === 0 || isDownloading}
          onClick={handleClickDownload}
          data-testid="syslog-download-btn"
        >
          {concat([t('vehicle.syslog'), t('common.action.download')], true)}
          {isDownloading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top={0}
              left={0}
              mt={1.5}
              width="100%"
            >
              <CircularProgress size={18} thickness={6} />
            </Box>
          )}
        </Button>
      </Badge>
    </Box>
  );
};

export default React.memo(SyslogDownloadButton);
