import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePrevious } from 'react-use';
import { DateTime } from 'luxon';
import {
  hasAlertAtom,
  MAX_ALERT_NUM,
  useNotification,
} from '@data/notification';
import { useSettings } from '@data/settings/hooks';
import { useVehicleSnoozeStuckAlarmAPI } from '@api/fms';
import { useNavigate } from 'react-router-dom';
import { SCOPES, useHasScope } from '@data/auth';
import { isEqual } from 'lodash';
import type { VehicleAlertNotifyOption } from '@data/notification/vehicle/types';
import {
  getVehicleAlertLevel,
  vehicleAlertListAtom,
} from '@data/notification/vehicle';
import { VehicleAlertContent } from '@components/common/molecules';
import { environmentOptionAtom } from '@data/fms/environment/states';
import {
  allVehiclesSelector,
  connectedVehiclesSelector,
} from '@data/fms/vehicle/states';
import { isDisconnected } from '@data/fms/vehicle/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  getIsFirstDiagnosticsNoticeNotification,
  getNotifiableDiagnosticsNoticeDiff,
} from './utils';

const VehicleAlertNotification: React.FC = () => {
  const connectedVehicles = useAtomValue(connectedVehiclesSelector(false));
  const prevConnectedVehicles = usePrevious(connectedVehicles);
  const allVehicles = useAtomValue(allVehiclesSelector);
  const prevAllVehicles = usePrevious(allVehicles);
  const { notifyAlert } = useNotification();
  const hasAlert = useAtomValue(hasAlertAtom);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { settings } = useSettings();
  const { postVehicleSnoozeStuckAlarm } = useVehicleSnoozeStuckAlarmAPI();
  const navigate = useNavigate();
  const environmentOption = useAtomValue(environmentOptionAtom);
  const getHasScope = useHasScope();
  const isAllVehiclesFirstNotified = useRef(false);
  const isConnectedVehiclesFirstNotifed = useRef(false);
  const setVehicleAlertList = useSetAtom(vehicleAlertListAtom);

  /**
   * 立ち往生通知を一時停止する権限があるかどうか
   */
  const hasRequestSnoozeStuckAlarmScope = useMemo(
    () => getHasScope(SCOPES.UpdateVehicleStuckAlarmSnooze),
    [getHasScope],
  );

  /**
   * 車両固有パラメータのデプロイ権限があるかどうか
   */
  const hasDeployCalibrationParameterScope = useMemo(
    () => getHasScope(SCOPES.DeployCalibrationParameter),
    [getHasScope],
  );

  /**
   * 車両固有パラメータの候補パラメータの承認権限があるかどうか（候補パラメータ関連の通知を出すかどうか）
   */
  const hasApproveCalibrationParameterCandidatesScope = useMemo(
    () => getHasScope(SCOPES.ApproveCalibrationParameterCandidates),
    [getHasScope],
  );

  /**
   * リンクボタンクリック
   */
  const handleClickLinkButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const to = e.currentTarget.dataset.linkTo;
      if (isNullOrUndefined(to)) return;
      navigate(to);
    },
    [navigate],
  );

  /**
   * @param {VehicleAlertNotifyOption} options
   * 通知を実行
   */
  const notifyVehicleAlert = useCallback(
    (options: VehicleAlertNotifyOption) => {
      notifyAlert({
        ...options,
        variant: getVehicleAlertLevel(options.alert.type),
        message: (
          <div
            data-alert="true"
            data-testid={options.testIdAttrs?.wrapperTestId}
          >
            <VehicleAlertContent
              alert={options.alert}
              testIdAttrs={options.alertTestIdAttrs}
            />
          </div>
        ),
        enterFunction: () => {
          setVehicleAlertList((prevState) => {
            const clone = prevState.concat();
            if (clone.length === MAX_ALERT_NUM) {
              clone.pop();
            }
            const id = `${options.alert.notifiedAt}_${options.alert.type}_${options.alert.vehicle.vehicle_id}`;
            return [{ ...options.alert, id }, ...clone];
          });
        },
      });
    },
    [notifyAlert, setVehicleAlertList],
  );

  /**
   * アラート音を再生
   */
  useEffect(() => {
    if (isNullOrUndefined(audioRef.current)) return;
    if (!hasAlert) {
      audioRef.current.pause();
      return;
    }
    audioRef.current.volume = settings.alert.volume;
    audioRef.current.play().catch((error) => console.log(error));
  }, [hasAlert, settings.alert.volume]);

  useEffect(() => {
    const notifiedAt = DateTime.now().toFormat('yyyy/MM/dd HH:mm:ss');

    // Console アクセス時の通知
    if (!isConnectedVehiclesFirstNotifed.current && connectedVehicles.length > 0) {
      connectedVehicles.forEach((vehicle) => {
        // カスタム通知
        const isFirstDiagnosticsNoticeNotification =
          getIsFirstDiagnosticsNoticeNotification(
            vehicle.telemetry.diagnostics_notice,
          );
        if (isFirstDiagnosticsNoticeNotification) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'diagnosticsNotice',
            },
          });
        }
      });
      isConnectedVehiclesFirstNotifed.current = true;
    }

    if (
      connectedVehicles.length === 0 ||
      isNullOrUndefined(prevConnectedVehicles)
    ) {
      return;
    }

    connectedVehicles.forEach((vehicle) => {
      const prevVehicle = prevConnectedVehicles.find(
        (prev) => prev.vehicle_id === vehicle.vehicle_id,
      );
      if (!isNullOrUndefined(prevVehicle)) {
        // 立ち往生通知
        if (
          vehicle.telemetry.stuck_alarm &&
          !prevVehicle.telemetry.stuck_alarm
        ) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'stuckAlarm',
            },
            closeFunction: () => {
              if (hasRequestSnoozeStuckAlarmScope) {
                postVehicleSnoozeStuckAlarm(vehicle.vehicle_id);
              }
            },
          });
        }

        // バッテリー通知
        if (
          !isNullOrUndefined(environmentOption) &&
          environmentOption.enable_battery_alarm &&
          !isNullOrUndefined(vehicle.telemetry.battery)
        ) {
          // バッテリー通知がONになっている
          if (
            vehicle.telemetry.battery <=
            environmentOption.battery_alarm_threshold
          ) {
            // バッテリー値が閾値以下の場合
            if (vehicle.telemetry.battery < prevVehicle.telemetry.battery) {
              // 直前のバッテリー値よりも少なくなっている
              if (
                vehicle.telemetry.battery ===
                  environmentOption.battery_alarm_threshold ||
                vehicle.telemetry.battery % 5 === 0
              ) {
                // 閾値と同じ地になった場合 or バッテリーが5の倍数の場合
                notifyVehicleAlert({
                  alert: {
                    vehicle,
                    notifiedAt,
                    type: 'batteryAlarm',
                  },
                });
              }
            }
          }
        }

        // カスタム通知
        const notifiableDiagnosticsNotice = getNotifiableDiagnosticsNoticeDiff(
          prevVehicle.telemetry.diagnostics_notice,
          vehicle.telemetry.diagnostics_notice,
        );
        if (notifiableDiagnosticsNotice.length) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'diagnosticsNotice',
            },
          });
        }
      }
    });
  }, [
    hasRequestSnoozeStuckAlarmScope,
    connectedVehicles,
    prevConnectedVehicles,
    notifyVehicleAlert,
    postVehicleSnoozeStuckAlarm,
    handleClickLinkButton,
    environmentOption,
  ]);

  useEffect(() => {
    const notifiedAt = DateTime.now().toFormat('yyyy/MM/dd HH:mm:ss');

    if (allVehicles.length === 0 || isNullOrUndefined(prevAllVehicles)) {
      return;
    }

    // Console アクセス時の通知
    if (!isAllVehiclesFirstNotified.current && allVehicles.length > 0) {
      allVehicles.forEach((vehicle) => {
        // agent_emergency 通知
        if (
          !isNullOrUndefined(vehicle.telemetry.agent_emergency) &&
          vehicle.telemetry.agent_emergency.length > 0
        ) {
          vehicle.telemetry.agent_emergency.forEach((message) => {
            if (message.length > 0) {
              notifyVehicleAlert({
                alert: {
                  vehicle,
                  notifiedAt,
                  type: 'agentError',
                  agentErrorMessage: message,
                },
              });
            }
          });
        }

        // キャリブレーションが必要な場合の通知
        if (
          hasApproveCalibrationParameterCandidatesScope &&
          !isNullOrUndefined(
            vehicle.calibration_parameter?.new_parameter_candidate_statuses
              ?.accel_brake_map.calibration_status,
          )
        ) {
          const accelBrakeMapCalibrationStatus =
            vehicle.calibration_parameter.new_parameter_candidate_statuses
              .accel_brake_map.calibration_status;
          if (accelBrakeMapCalibrationStatus === 'calibration_required') {
            notifyVehicleAlert({
              alert: {
                vehicle,
                notifiedAt,
                type: 'individualParameterSuggested',
              },
            });
          }
        }
      });
      isAllVehiclesFirstNotified.current = true;
    }

    allVehicles.forEach((vehicle) => {
      const prevVehicle = prevAllVehicles.find(
        (prev) => prev.vehicle_id === vehicle.vehicle_id,
      );

      if (!isNullOrUndefined(prevVehicle)) {
        // エラー通知
        if (
          vehicle.telemetry.has_error_diagnostics &&
          !isNullOrUndefined(environmentOption) &&
          !isNullOrUndefined(environmentOption.error_alert_condition)
        ) {
          if (environmentOption.error_alert_condition === 'emergency') {
            // telemetry.is_emergency が true の場合通知
            // 直前の条件値の変更を見る
            if (
              !prevVehicle.telemetry.is_emergency &&
              vehicle.telemetry.is_emergency
            ) {
              notifyVehicleAlert({
                alert: {
                  vehicle,
                  notifiedAt,
                  type: 'vehicleError',
                },
              });
            }
          }
          if (
            environmentOption.error_alert_condition === 'emergency_during_auto'
          ) {
            // telemetry.is_emergency が true
            // telemetry.drive_mode が auto の場合通知
            if (
              vehicle.telemetry.is_emergency &&
              vehicle.telemetry.drive_mode === 'auto'
            ) {
              // 直前の条件値の変更を見る
              if (
                !prevVehicle.telemetry.is_emergency ||
                prevVehicle.telemetry.drive_mode !== 'auto'
              ) {
                notifyVehicleAlert({
                  alert: {
                    vehicle,
                    notifiedAt,
                    type: 'vehicleError',
                  },
                });
              }
            }
          }
          if (environmentOption.error_alert_condition === 'emergency_stopped') {
            // telemetry.is_emergency が true
            // telemetry.is_emergency_holding が true
            // telemetry.drive_mode が auto の場合通知
            if (
              vehicle.telemetry.is_emergency &&
              vehicle.telemetry.is_emergency_holding &&
              vehicle.telemetry.drive_mode === 'auto'
            ) {
              // 直前の条件値の変更を見る
              if (
                !prevVehicle.telemetry.is_emergency ||
                !prevVehicle.telemetry.is_emergency_holding ||
                prevVehicle.telemetry.drive_mode !== 'auto'
              ) {
                notifyVehicleAlert({
                  alert: { vehicle, notifiedAt, type: 'vehicleError' },
                });
              }
            }
          }
        }

        // 切断通知
        if (!isDisconnected(prevVehicle) && isDisconnected(vehicle)) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'disconnect',
            },
            testIdAttrs: {
              closeButtonTestId:
                'stop_fsim_snackbar_disconnected-click-close_button',
            },
            alertTestIdAttrs: {
              titleTestId: 'stop_fsim_snackbar_disconnected_header-status-h6',
              messageTestId:
                'stop_fsim_snackbar_disconnected_vehicleName-status-p',
            },
          });
        }

        // ネットワーク警告
        if (
          environmentOption?.enable_telemetry_arrival_error_alarm &&
          !prevVehicle.telemetry.telemetry_arrival_error &&
          vehicle.telemetry.telemetry_arrival_error
        ) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'telemetryArrivalErrorAlarm',
            },
          });
        }

        // agent_emergency 通知
        if (
          !isNullOrUndefined(vehicle.telemetry.agent_emergency) &&
          !isEqual(
            prevVehicle.telemetry.agent_emergency,
            vehicle.telemetry.agent_emergency,
          ) &&
          vehicle.telemetry.agent_emergency.length > 0
        ) {
          vehicle.telemetry.agent_emergency.forEach((message) => {
            // 一つ前の agent_emergency に含まれていなかったら通知
            if (
              prevVehicle.telemetry.agent_emergency.indexOf(message) < 0 &&
              message.length > 0
            ) {
              notifyVehicleAlert({
                alert: {
                  vehicle,
                  notifiedAt,
                  type: 'agentError',
                  agentErrorMessage: message,
                },
              });
            }
          });
        }

        // agent_error 通知
        if (
          !isEqual(
            prevVehicle.telemetry.agent_error,
            vehicle.telemetry.agent_error,
          ) &&
          vehicle.telemetry.agent_error.length > 0
        ) {
          vehicle.telemetry.agent_error.forEach((message) => {
            // 一つ前の agent_error に含まれていなかったら通知
            if (
              prevVehicle.telemetry.agent_error.indexOf(message) < 0 &&
              message.length > 0
            ) {
              notifyVehicleAlert({
                alert: {
                  vehicle,
                  notifiedAt,
                  type: 'agentError',
                  agentErrorMessage: message,
                },
              });
            }
          });
        }

        // 地図更新失敗 通知
        if (
          !isEqual(
            prevVehicle.map_update_status_reason,
            vehicle.map_update_status_reason,
          ) &&
          !isNullOrUndefined(vehicle.map_update_status_reason) &&
          vehicle.map_update_status_reason.length > 0
        ) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'mapUpdateFailure',
            },
          });
        }

        // Web.Auto Agent更新失敗 通知
        if (
          !isEqual(
            prevVehicle.edge_deployment_status_reason,
            vehicle.edge_deployment_status_reason,
          ) &&
          !isNullOrUndefined(vehicle.edge_deployment_status_reason) &&
          vehicle.edge_deployment_status_reason.length > 0
        ) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'agentUpdateFailure',
            },
          });
        }

        // Firmware更新失敗 通知
        if (
          !isEqual(
            prevVehicle.firmware_deployment_status_reason,
            vehicle.firmware_deployment_status_reason,
          ) &&
          !isNullOrUndefined(vehicle.firmware_deployment_status_reason) &&
          vehicle.firmware_deployment_status_reason.length > 0
        ) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'firmwareUpdateFailure',
            },
          });
        }

        // 車両固有パラメータ通知
        if (
          hasApproveCalibrationParameterCandidatesScope &&
          !isNullOrUndefined(
            prevVehicle.calibration_parameter?.new_parameter_candidate_statuses
              ?.accel_brake_map.calibration_status,
          ) &&
          !isNullOrUndefined(
            vehicle.calibration_parameter?.new_parameter_candidate_statuses
              ?.accel_brake_map.calibration_status,
          )
        ) {
          const prevAccelBrakeMapCalibrationStatus =
            prevVehicle.calibration_parameter.new_parameter_candidate_statuses
              .accel_brake_map.calibration_status;
          const accelBrakeMapCalibrationStatus =
            vehicle.calibration_parameter.new_parameter_candidate_statuses
              .accel_brake_map.calibration_status;
          if (
            prevAccelBrakeMapCalibrationStatus !== 'calibration_required' &&
            accelBrakeMapCalibrationStatus === 'calibration_required'
          ) {
            // パラメータ更新が必要
            notifyVehicleAlert({
              alert: {
                vehicle,
                notifiedAt,
                type: 'individualParameterSuggested',
              },
            });
          }
        }

        // importingからimportedに変化した場合のみ通知
        if (
          hasApproveCalibrationParameterCandidatesScope &&
          !isNullOrUndefined(
            vehicle.calibration_parameter?.new_parameter_candidate_statuses
              ?.accel_brake_map.candidates_status,
          )
        ) {
          const prevAccelBrakeMapCandidatesStatus =
            prevVehicle.calibration_parameter.new_parameter_candidate_statuses
              ?.accel_brake_map.candidates_status;
          const accelBrakeMapCandidatesStatus =
            vehicle.calibration_parameter.new_parameter_candidate_statuses
              ?.accel_brake_map.candidates_status;
          if (
            accelBrakeMapCandidatesStatus === 'imported' &&
            prevAccelBrakeMapCandidatesStatus === 'importing'
          ) {
            // 新バージョンが生成されていて展開が必要
            notifyVehicleAlert({
              alert: {
                vehicle,
                notifiedAt,
                type: 'individualParameterImported',
              },
            });
          }
        }

        // 車両固有パラメータが改変された場合
        if (
          hasDeployCalibrationParameterScope &&
          !prevVehicle.calibration_parameter.is_tampered &&
          vehicle.calibration_parameter.is_tampered &&
          vehicle.calibration_parameter.deployment_status === 'success'
        ) {
          notifyVehicleAlert({
            alert: {
              vehicle,
              notifiedAt,
              type: 'individualParameterTampered',
            },
          });
        }
      }
    });
  }, [
    allVehicles,
    prevAllVehicles,
    notifyVehicleAlert,
    handleClickLinkButton,
    environmentOption,
    hasDeployCalibrationParameterScope,
    hasApproveCalibrationParameterCandidatesScope,
  ]);

  return (
    <audio
      ref={audioRef}
      src="/assets/sound/attention.mp3"
      muted={settings.alert.mute}
      autoPlay
      loop
    />
  );
};

export default VehicleAlertNotification;
