import {
  Box,
  CircularProgress,
  colors,
  Icon,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import DataCell, { DataCellBox } from '../../atoms/DataCell';
import { useTranslation } from 'react-i18next';
import { useConcatWord } from '@utils/i18n';
import { DateTime } from 'luxon';
import { Minus } from 'react-feather';
import type { LogFile, LogType } from '@data/datahub/log/types';
import { convertBytesToMb } from '@utils/file';
import { useLogFileAPI } from '@api/datahub';

type DateProps = {
  date: string;
};

const Date: React.FC<DateProps> = React.memo(({ date }: DateProps) => {
  const dateText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('yyyy/MM/dd');
  }, [date]);

  const timeText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('HH:mm:ss');
  }, [date]);

  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {dateText}
      </Typography>
      <Typography>{timeText}</Typography>
    </Box>
  );
});

Date.displayName = 'Date';

type DownloadCellProps = {
  index: number;
  data: LogFile;
  type: LogType;
};

const DownloadCell: React.FC<DownloadCellProps> = ({ index, data, type }) => {
  const { downloadFile } = useLogFileAPI();
  const [isDownLoading, setIsDownloading] = useState(false);

  const handleClickDownload = useCallback(async () => {
    setIsDownloading(true);
    await downloadFile(type, data);
    setIsDownloading(false);
  }, [downloadFile, data, type]);

  return (
    <DataCellBox>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Box width={30} display="flex" justifyContent="center">
          {isDownLoading ? (
            <CircularProgress size={18} thickness={6} />
          ) : (
            <IconButton
              size="small"
              color="primary"
              data-index={index}
              data-testid="download_icon"
              onClick={handleClickDownload}
            >
              <Icon>get_app</Icon>
            </IconButton>
          )}
        </Box>
        <Typography>{convertBytesToMb(data.file_size)} MB</Typography>
      </Stack>
    </DataCellBox>
  );
};

type Props = {
  files: LogFile[];
  height: number;
  isPeriod: boolean;
};

const LogFileList: React.FC<Props> = ({ files, isPeriod, height }) => {
  const { t } = useTranslation();
  const { concat } = useConcatWord();

  const columns = useMemo(
    () => [
      // 562
      {
        label: 'No',
        dataKey: 'no',
        width: 56,
      },
      {
        label: concat([t('common.action.record'), t('common.date.time')]),
        dataKey: 'record_time',
        width: 256,
      },
      {
        label: t('common.action.download'),
        dataKey: 'download',
        width: 250,
      },
    ],
    [t, concat],
  );

  const headerRenderer = useCallback(
    ({ label }: { label: React.ReactNode }) => {
      return (
        <DataCell variant="head" align="left" component="div">
          <span>{label}</span>
        </DataCell>
      );
    },
    [],
  );

  const cellRenderer = useCallback(
    ({
      dataKey,
      rowData,
      rowIndex,
    }: {
      dataKey: string;
      rowData: LogFile;
      rowIndex: number;
    }) => {
      const getCellData = () => {
        if (dataKey === 'no') return <DataCellBox>{rowIndex + 1}</DataCellBox>;
        if (dataKey === 'record_time') {
          return (
            <DataCellBox>
              <DataCellBox>
                <Date date={rowData.record_start} />
                {isPeriod && (
                  <>
                    <Box mx={2} mt={6.5}>
                      <Minus size={14} color={colors.grey[500]} />
                    </Box>
                    <Date date={rowData.record_end} />
                  </>
                )}
              </DataCellBox>
            </DataCellBox>
          );
        }
        if (dataKey === 'download') {
          return (
            <DownloadCell
              type={isPeriod ? 'tcpdump' : 'coredump'}
              index={rowIndex}
              data={rowData}
            />
          );
        }
      };

      return (
        <DataCell component="div" sx={{ height: 76 }}>
          {getCellData()}
        </DataCell>
      );
    },
    [isPeriod],
  );

  return (
    <Box sx={{ width: '100%', height: height }}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={45}
            rowCount={files.length}
            rowGetter={({ index }) => files[index]}
            rowHeight={76}
            columns={files}
          >
            {columns.map(({ dataKey, ...other }) => (
              <Column
                key={dataKey}
                dataKey={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    label: headerProps.label,
                  })
                }
                cellRenderer={({ dataKey, rowData, rowIndex }) =>
                  cellRenderer({
                    dataKey,
                    rowData,
                    rowIndex,
                  })
                }
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  );
};

export default React.memo(LogFileList);
