import {
  Box,
  Icon,
  IconButton,
  Typography,
  styled,
  Tooltip,
  colors,
  CircularProgress,
} from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import type { TableRowProps } from 'react-virtualized';
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  Table,
} from 'react-virtualized';
import DataCell, { DataCellBox } from '../../atoms/DataCell';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useHasScope, SCOPES } from '@data/auth';
import type { EventData } from '@data/datahub/event/types';
import { isNetworkDisconnectedData } from '@data/datahub/event';
import { Info } from '@mui/icons-material';
import type { HistoryData } from '@components/pages/DrivingHistory/types';
import type { EmergencyTimestamp } from '@data/datahub/emergency/types';
import { Minus } from 'react-feather';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { BagFile } from '@data/datahub/log/types';
import { useRootPath } from '@data/fms/environment/hooks';
import type { SetStateAction } from 'jotai';
import { useLogFileAPI } from '@api/datahub';

type DateProps = {
  date: string;
};

const Date: React.FC<DateProps> = memo(({ date }: DateProps) => {
  const getDate = useCallback((value: string) => {
    if (!value) return null;
    return DateTime.fromISO(value).toFormat('yyyy/MM/dd');
  }, []);

  const getTime = useCallback((value: string) => {
    if (!value) return null;
    return DateTime.fromISO(value).toFormat('HH:mm:ss.SSS');
  }, []);

  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {getDate(date)}
      </Typography>
      <Typography>{getTime(date)}</Typography>
    </Box>
  );
});

Date.displayName = 'Date';

type DownloadCellProps = {
  data: BagFile | undefined;
};

const DownloadCell: React.FC<DownloadCellProps> = memo(({ data }) => {
  const { downloadFile } = useLogFileAPI();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClickDownload = useCallback(async () => {
    if (!data) return;
    setIsDownloading(true);
    await downloadFile('bag', data);
    setIsDownloading(false);
  }, [data, downloadFile]);

  return (
    <DataCellBox>
      {data ? (
        isDownloading ? (
          <Box ml={1}>
            <CircularProgress size={18} thickness={6} />
          </Box>
        ) : (
          <IconButton
            color="primary"
            size="small"
            style={{ padding: 0 }}
            onClick={handleClickDownload}
          >
            <Icon>get_app</Icon>
          </IconButton>
        )
      ) : (
        <IconButton disabled size="small" data-testid="bag_icon">
          <Icon>not_interested</Icon>
        </IconButton>
      )}
    </DataCellBox>
  );
});

DownloadCell.displayName = 'DownloadCell';

type MergedData = {
  no?: number;
  sort_timestamp: string;
  data: EmergencyTimestamp | EventData;
};

type Props = {
  data: HistoryData;
  setData: SetAtom<[SetStateAction<HistoryData>], void>;
  height: number;
  showCreateMemoButton?: boolean;
};

const ErrorList: React.FC<Props> = ({
  data,
  height,
  showCreateMemoButton = true,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rootPath = useRootPath();
  const params = useParams<{ vehicle_id: string }>();
  const getHasScope = useHasScope();

  const enableCreateMemo = useMemo(
    () => showCreateMemoButton && getHasScope(SCOPES.CreateDrivingNote),
    [getHasScope, showCreateMemoButton],
  );

  const columns = useMemo(() => {
    const buttonCellWidth = enableCreateMemo ? 70 : 100;
    const arr = [
      // 562
      {
        label: 'No',
        dataKey: 'no',
        width: 56,
      },
      {
        label: t('common.date.time'),
        dataKey: 'date',
        width: 306,
      },
      {
        label: 'Bag',
        dataKey: 'bag',
        width: buttonCellWidth,
      },
      {
        label: t('common.general.detail'),
        dataKey: 'error',
        width: buttonCellWidth,
      },
    ];
    if (enableCreateMemo) {
      return [
        ...arr,
        {
          label: t('driving_memo.category.memo'),
          dataKey: 'memo',
          width: 60,
        },
      ];
    }
    return arr;
  }, [t, enableCreateMemo]);

  const mergedDatas = useMemo(() => {
    const mergedArr: MergedData[] = [
      ...data.emergencyTimestamps.map((data, i) => ({
        no: i,
        sort_timestamp: data.start_timestamp,
        data,
      })),
      ...data.shutdownEvents.map((data) => ({
        sort_timestamp: data.timestamp,
        data,
      })),
      ...data.networkConnectionEvents.map((data) => ({
        sort_timestamp: data.timestamp,
        data,
      })),
    ];
    mergedArr.sort(
      (a: MergedData, b: MergedData) =>
        DateTime.fromISO(a.sort_timestamp).diff(
          DateTime.fromISO(b.sort_timestamp),
          'seconds',
        ).seconds,
    );
    return mergedArr;
  }, [data]);

  const handleClickDetailIcon = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const index = Number(e.currentTarget.dataset.index);
      const targetEmergencyTimestamp = data.emergencyTimestamps[index];
      if (isNullOrUndefined(targetEmergencyTimestamp)) return;
      const params = new URLSearchParams(window.location.search);
      params.append(
        'error_time',
        `${DateTime.fromISO(
          targetEmergencyTimestamp.start_timestamp,
        ).valueOf()}_${DateTime.fromISO(
          targetEmergencyTimestamp.end_timestamp,
        ).valueOf()}`,
      );
      navigate(`${window.location.pathname}?${params.toString()}`);
    },
    [data.emergencyTimestamps, navigate],
  );

  const handleClickCreateMemo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const date = e.currentTarget.dataset.errorTime;
      if (!date) return;
      const dateLuxon = DateTime.fromISO(date);
      if (!dateLuxon.isValid) return;
      const linkPath = `${rootPath}driving-note-register/${
        params.vehicle_id
      }/?category=bug&date=${dateLuxon.toUnixInteger()}`;
      navigate(linkPath);
    },
    [navigate, rootPath, params.vehicle_id],
  );

  const getBagFile = useCallback(
    (timestamp: EmergencyTimestamp): BagFile | undefined => {
      return data.bagFiles.find((bag) => {
        const startTime = DateTime.fromISO(
          timestamp.start_timestamp ?? '',
        ).valueOf();
        const endTime = DateTime.fromISO(
          timestamp.end_timestamp ?? '',
        ).valueOf();
        const recordStartTime = DateTime.fromISO(bag.record_start).valueOf();
        const recordEndTime = DateTime.fromISO(bag.record_end).valueOf();
        if (
          (recordStartTime < startTime && startTime < recordEndTime) ||
          (recordStartTime < endTime && endTime < recordEndTime)
        ) {
          return bag;
        }
        return null;
      });
    },
    [data.bagFiles],
  );

  const headerRenderer = useCallback(({ label }: { label: string }) => {
    return (
      <DataCell variant="head" align="left" component="div">
        <span>{label}</span>
      </DataCell>
    );
  }, []);

  const cellRenderer = useCallback(
    ({ dataKey, rowData }: { dataKey: string; rowData: MergedData }) => {
      const isErrorData = !isNetworkDisconnectedData(rowData.data);
      const getCellData = () => {
        if (dataKey === 'no') {
          return (
            <DataCellBox>
              {rowData.no !== undefined ? (
                rowData.no + 1
              ) : (
                <Box pl={1.5}>-</Box>
              )}
            </DataCellBox>
          );
        }
        if (!isErrorData) {
          // network connection || shutdown event
          const data = rowData.data as EventData;
          if (dataKey === 'date') {
            return (
              <DataCellBox>
                <Date date={data.timestamp} />
              </DataCellBox>
            );
          }
          if (dataKey === 'bag') {
            return (
              <DataCellBox>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Box sx={{ maxWidth: 200 }}>
                      {t(
                        `driving_history.error_list.event.tooltip.${data.type}`,
                      )}
                    </Box>
                  }
                >
                  <Info color="primary" fontSize="small" sx={{ mr: 1 }} />
                </Tooltip>
                {t(`common.datahub.event.type.${data.type}`)}
              </DataCellBox>
            );
          }
          return null;
        }

        // Error

        const data = rowData.data as EmergencyTimestamp;

        if (dataKey === 'date' && data.start_timestamp) {
          return (
            <DataCellBox data-testid="error_time">
              <Date date={data.start_timestamp} />
              <Box mx={2} mt={6.5}>
                <Minus size={14} color={colors.grey[500]} />
              </Box>
              <Date date={data.end_timestamp} />
            </DataCellBox>
          );
        }
        if (dataKey === 'bag') {
          const bagFile = getBagFile(data);
          return <DownloadCell data={bagFile} />;
        }
        if (dataKey === 'error') {
          return (
            <DataCellBox>
              <IconButton
                size="small"
                data-index={rowData.no}
                onClick={handleClickDetailIcon}
                data-testid="detail_icon"
              >
                <Icon>visibility</Icon>
              </IconButton>
            </DataCellBox>
          );
        }
        if (dataKey === 'memo') {
          return (
            <DataCellBox>
              <IconButton
                size="small"
                data-index={rowData.no}
                data-error-time={data.start_timestamp}
                onClick={handleClickCreateMemo}
                data-testid="memo_icon"
              >
                <Icon>create</Icon>
              </IconButton>
            </DataCellBox>
          );
        }
      };

      return (
        <DataCell component="div" sx={{ height: 60 }}>
          {getCellData()}
        </DataCell>
      );
    },
    [getBagFile, handleClickDetailIcon, handleClickCreateMemo, t],
  );

  const rowRenderer = useCallback((props: TableRowProps) => {
    if (isNetworkDisconnectedData(props.rowData.data)) {
      return defaultTableRowRenderer({
        ...props,
        className: `${props.className} event_data_row`,
        columns: props.columns.splice(0, 3),
      });
    }
    return defaultTableRowRenderer(props);
  }, []);

  const contents = useMemo(() => {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={45}
            rowCount={mergedDatas.length}
            rowRenderer={(rowProps) => rowRenderer(rowProps)}
            rowGetter={({ index }) => mergedDatas[index]}
            rowHeight={60}
            columns={columns}
          >
            {columns.map(({ dataKey, label, ...other }) => (
              <Column
                key={dataKey}
                dataKey={dataKey}
                headerRenderer={() =>
                  headerRenderer({
                    label,
                  })
                }
                cellRenderer={({ dataKey, rowData }) =>
                  cellRenderer({ dataKey, rowData })
                }
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    );
  }, [cellRenderer, headerRenderer, rowRenderer, mergedDatas, columns]);

  return <Wrapper height={height}>{contents}</Wrapper>;
};

export default memo(ErrorList);

const Wrapper = styled(Box)`
  width: 100%;

  div.event_data_row {
    div:last-of-type {
      flex: 0 1 270px !important;
    }
  }
`;
